
import { Options, Vue } from 'vue-class-component';
import * as OM from "@/Model";
import { UngloverMeetOptinClient } from '@/services/Services';

@Options({
    components: {
    }
})
export default class optinMeet extends Vue {

    items: OM.UngloverMeetOptin[] = [];

    created() {
        this.init()
    }

    init(){
        UngloverMeetOptinClient.getAll()
        .then( x => {
            this.items = x;
        })
    }

}
